<template>
  <section
    v-if="Object.keys(data).length"
    id="pricing-plan"
  >
    <!-- title text and switch button -->
    <div class="text-center">
      <h1 class="mt-5">
        {{ $t('ativacao.title') }}
      </h1>
      <p class="mb-2 pb-75">
        {{ $t('ativacao.text') }}
      </p>
    </div>
    <!--/ title text and switch button -->
    <!-- pricing plan cards -->
    <b-row
      md="4"
      class="pricing-card"
    >
      <b-col
        offset-sm-2
        sm="10"
        md="12"
        offset-lg="2"
        lg="10"
        class="mx-auto"
      >
        <b-row
          v-for="plano in data"
          :key="plano.id"
        >
          <b-col
            v-for="planoVar in plano.planoVariacao"
            :key="planoVar.id"
            md="3"
          >
            <b-card
              align="center"
            >
              <div
                :style="{ visibility: planoVar.popular ? 'visible' : 'hidden' }"
                class="pricing-badge text-right"
              >
                <b-badge
                  variant="light-primary"
                  pill
                >
                  {{ $t('ativacao.badgeText') }}
                </b-badge>
              </div>

              <!-- img -->
              <b-img
                :src="require('@/assets/images/illustration/badge.svg')"
                class="mb-2 mt-2"
                alt="basic svg img"
              />
              <!--/ img -->
              <h3>
                {{ plano.nome }}
                <b-badge variant="warning">
                  {{ planoVar.nome }}
                </b-badge>
              </h3>

              <!-- annual plan -->
              <div class="annual-plan">
                <div class="plan-price mt-2">
                  <sup class="font-medium-1 font-weight-bold text-primary">$</sup>
                  <span class="pricing-basic-value font-weight-bolder text-primary">{{ planoVar.precoFinal }}</span>
                  <div class="pricing-duration text-body font-small-3 font-weight-bold mt-1">
                    (
                    <template v-if="planoVar.preco == 0">
                      {{ $t('ativacao.licencia') }} {{ $t('ativacao.gratis') }}
                    </template>
                    <template v-else>
                      {{ planoVar.preco }} {{ $t('ativacao.licencia') }}
                    </template>

                    <template v-if="planoVar.creditoTaxaDesempenho > 0">
                      + {{ planoVar.creditoTaxaDesempenho }} {{ $t('ativacao.creditoTaxaDesempenho') }}
                    </template>
                    )
                  </div>
                </div>
              </div>
              <!--/ annual plan -->

              <b-list-group class="list-group-circle text-left">
                <b-list-group-item
                  v-for="(data,index) in planoVar.beneficio"
                  :key="index"
                >
                  {{ data }}
                </b-list-group-item>
              </b-list-group>
              <!-- buttons -->
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                block
                class="mt-2"
                :variant="planoVar.popular ? 'success' : 'outline-success'"
                :to="{ name: 'checkout', params: { nomePlano: plano.nomeUrl, nomePlanoVariacao: planoVar.nomeUrl } }"
              >
                {{ $t('ativacao.compraBtn') }}
              </b-button>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!--/ pricing plan cards -->

  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BImg, BButton, BBadge, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import ativacaoStoreModule from './ativacaoStoreModule'

const ATIVACAO_STORE_MODULE_NAME = 'ativacao'

/* eslint-disable global-require */
export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BBadge,
    BImg,
    BListGroup,
    BListGroupItem,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {},
    }
  },

  created() {
    if (!store.hasModule(ATIVACAO_STORE_MODULE_NAME)) store.registerModule(ATIVACAO_STORE_MODULE_NAME, ativacaoStoreModule)
    this.fetch()
  },

  beforeDestroy() {
    if (store.hasModule(ATIVACAO_STORE_MODULE_NAME)) store.unregisterModule(ATIVACAO_STORE_MODULE_NAME)
  },

  methods: {
    fetch() {
      store
        .dispatch('ativacao/getPlanos')
        .then(response => {
          this.data = response.data
        })
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-pricing.scss';
</style>
