import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    getPlanos() {
      return new Promise((resolve, reject) => {
        axios.get('/plano')
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

  },
}
